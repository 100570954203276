import { createSlice } from '@reduxjs/toolkit';
import { ErrorTypes, ErrorTypesToMessages } from '../../constants/ErrorMessages';
import { UserGuideStepStatus } from '../../constants/GuideChat';
import { ItemNum } from '../../constants/ItemNum';
import { StatusTypes } from '../../constants/StatusTypes';
import { guideChatRequest } from './thunk';

const initialState = {
  guidePreviewsList: [],
  statusGuidePreviews: StatusTypes.Loading,
  errorGuidePreviews: null,

  currentGuideId: null,
  guideInfo: null,
  gyddeMessages: [],
  userMessages: {},
  status: null,
  error: null,
  guideCurrentStep: null,

  isStepConfirmed: false,

  isAllStepsCompleted: false,

  nextStepStatus: null,
  nextStepError: null,

  interactionStatus: null,
  interactionError: null,

  actionAnswersById: {},
  statusAction: StatusTypes.Loading,
  errorAction: null,
};

function handleGuideStepCompletion(state, action) {
  state.nextStepStatus = StatusTypes.Resolved;
  state.guideCurrentStep = action.payload;

  if (state.gyddeMessages.length >= ItemNum.One) {
    const updatedMessages = state.gyddeMessages.map((item, index, arr) =>
      index === arr.length - ItemNum.One
        ? { ...item, userQuestStepStatus: UserGuideStepStatus.Done }
        : item
    );

    state.gyddeMessages = [...updatedMessages, action.payload];

    if (action.payload.userQuestStepStatus !== UserGuideStepStatus.Done) {
      state.guideCurrentStep = action.payload;
    } else {
      state.guideCurrentStep = {
        ...action.payload,
        userQuestStepStatus: UserGuideStepStatus.Finish,
      };
      state.isAllStepsCompleted = true;
    }
  } else {
    state.guideCurrentStep = action.payload;
    state.gyddeMessages = [...state.gyddeMessages, action.payload];
  }
}

const processNextStepError = ({ payload }) => {
  const errorType = payload?.type;
  const errorMessage = payload?.errorMessage || '';

  const findErrorType = () => {
    return Object.keys(ErrorTypes).find((type) =>
      errorMessage.includes(ErrorTypesToMessages[type])
    );
  };

  return errorType === ErrorTypes.CAPTCHA_ERROR
    ? errorType
    : findErrorType() || errorMessage || 'ERROR';
};

export const guideChatSlice = createSlice({
  name: 'guideChat',
  initialState,
  reducers: {
    resetGuideChatState: () => initialState,
    clearChat: (state) => {
      state.status = null;
      state.gyddeMessages = [];
    },
    setChoosenGuideInfo: (state, action) => {
      state.guideInfo = action.payload;
      state.nextStepStatus = null;
    },
    addUserMessageByStepId: (state, action) => {
      state.userMessages = { ...state.userMessages, ...action.payload };
    },
    setIsStepConfirmed: (state, action) => {
      state.isStepConfirmed = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //preview cards in guides chat
      .addCase(guideChatRequest.getGuidePreviews.pending, (state) => {
        state.statusGuidePreviews = StatusTypes.Loading;
        state.errorGuidePreviews = null;
      })
      .addCase(guideChatRequest.getGuidePreviews.fulfilled, (state, action) => {
        state.statusGuidePreviews = StatusTypes.Resolved;
        state.guidePreviewsList = action.payload;
      })
      .addCase(guideChatRequest.getGuidePreviews.rejected, (state, action) => {
        state.statusGuidePreviews = StatusTypes.Rejected;
        state.errorGuidePreviews = action.payload;
      })
      // authorized quiz
      .addCase(guideChatRequest.actionQuiz.pending, (state) => {
        state.statusAction = StatusTypes.Loading;
        state.errorAction = null;
      })
      .addCase(guideChatRequest.actionQuiz.fulfilled, (state, action) => {
        state.statusAction = StatusTypes.Resolved;
        state.actionAnswersById = {
          ...state.actionAnswersById,
          [action.payload.questStepId]: action.payload,
        };
      })
      .addCase(guideChatRequest.actionQuiz.rejected, (state, action) => {
        state.statusAction = StatusTypes.Rejected;
        state.errorAction = action.payload;
      })

      // authorized link
      .addCase(guideChatRequest.actionLink.pending, (state) => {
        state.statusAction = StatusTypes.Loading;
        state.errorAction = null;
      })
      .addCase(guideChatRequest.actionLink.fulfilled, (state, action) => {
        state.statusAction = StatusTypes.Resolved;
        state.actionAnswersById = {
          ...state.actionAnswersById,
          [action.payload.questStepId]: action.payload,
        };
      })
      .addCase(guideChatRequest.actionLink.rejected, (state, action) => {
        state.statusAction = StatusTypes.Rejected;
        state.errorAction = action.payload;
      })

      //get all steps by guide Id
      .addCase(guideChatRequest.getAllSteps.pending, (state) => {
        state.status = StatusTypes.Loading;
        state.error = null;
      })
      .addCase(guideChatRequest.getAllSteps.fulfilled, (state, action) => {
        state.status = StatusTypes.Resolved;
        state.currentGuideId = action.meta.arg;
        state.gyddeMessages = action.payload;

        state.guideCurrentStep =
          action.payload[action.payload.length - ItemNum.One].userQuestStepStatus ===
          UserGuideStepStatus.Done
            ? {
                ...action.payload[action.payload.length - ItemNum.One],
                userQuestStepStatus: UserGuideStepStatus.Finish,
              }
            : action.payload[action.payload.length - ItemNum.One];
      })
      .addCase(guideChatRequest.getAllSteps.rejected, (state, action) => {
        state.status = StatusTypes.Rejected;
        state.error = action.payload;
      })

      // next step for authorized users
      .addCase(guideChatRequest.nextStep.pending, (state) => {
        state.nextStepStatus = StatusTypes.Loading;
        state.nextStepError = null;
      })
      .addCase(guideChatRequest.nextStep.fulfilled, handleGuideStepCompletion)
      .addCase(guideChatRequest.nextStep.rejected, (state, action) => {
        state.nextStepStatus = StatusTypes.Rejected;
        state.nextStepError = processNextStepError({ payload: action.payload.state });
      })
      // send gas
      .addCase(guideChatRequest.sendGasAndNext.pending, (state) => {
        state.nextStepStatus = StatusTypes.Loading;
        state.nextStepError = null;
      })
      .addCase(guideChatRequest.sendGasAndNext.fulfilled, handleGuideStepCompletion)
      .addCase(guideChatRequest.sendGasAndNext.rejected, (state, action) => {
        state.nextStepStatus = StatusTypes.Rejected;
        state.nextStepError = processNextStepError({ payload: action.payload });
      })
      // check subscription and next
      .addCase(guideChatRequest.checkSubscriptionAndNext.pending, (state) => {
        state.nextStepStatus = StatusTypes.Loading;
        state.nextStepError = null;
      })
      .addCase(
        guideChatRequest.checkSubscriptionAndNext.fulfilled,
        handleGuideStepCompletion
      )
      .addCase(guideChatRequest.checkSubscriptionAndNext.rejected, (state, action) => {
        const errorType = action.payload?.type;
        if (ErrorTypes[errorType] === ErrorTypes['NO_TELEGRAM']) {
          state.nextStepStatus = StatusTypes.RejectedByNoTelegram;
        } else {
          state.nextStepStatus = StatusTypes.Rejected;
        }
        state.nextStepError = processNextStepError({ payload: action.payload });
      })
      // leaveFeedbackAndNext
      .addCase(guideChatRequest.leaveFeedbackAndNext.pending, (state) => {
        state.nextStepStatus = StatusTypes.Loading;
        state.nextStepError = null;
      })
      .addCase(guideChatRequest.leaveFeedbackAndNext.fulfilled, handleGuideStepCompletion)
      .addCase(guideChatRequest.leaveFeedbackAndNext.rejected, (state, action) => {
        state.nextStepStatus = StatusTypes.Rejected;
        state.nextStepError = processNextStepError({ payload: action.payload });
      })
      // submitAdditionalWalletAndNext
      .addCase(guideChatRequest.submitAdditionalWalletAndNext.pending, (state) => {
        state.nextStepStatus = StatusTypes.Loading;
        state.nextStepError = null;
      })
      .addCase(
        guideChatRequest.submitAdditionalWalletAndNext.fulfilled,
        handleGuideStepCompletion
      )
      .addCase(
        guideChatRequest.submitAdditionalWalletAndNext.rejected,
        (state, action) => {
          state.nextStepStatus = StatusTypes.Rejected;
          state.nextStepError = processNextStepError({ payload: action.payload });
        }
      )
      // check likes and next
      .addCase(guideChatRequest.checkTwitterLikesAndNext.pending, (state) => {
        state.nextStepStatus = StatusTypes.Loading;
        state.nextStepError = null;
      })
      .addCase(
        guideChatRequest.checkTwitterLikesAndNext.fulfilled,
        handleGuideStepCompletion
      )
      .addCase(guideChatRequest.checkTwitterLikesAndNext.rejected, (state, action) => {
        state.nextStepStatus = StatusTypes.Rejected;
        state.nextStepError = processNextStepError({ payload: action.payload.state });
      })
      // authorized link
      .addCase(guideChatRequest.selectSocial.pending, (state) => {
        state.interactionStatus = StatusTypes.Loading;
        state.interactionError = null;
      })
      .addCase(guideChatRequest.selectSocial.fulfilled, (state) => {
        state.interactionStatus = StatusTypes.Resolved;
      })
      .addCase(guideChatRequest.selectSocial.rejected, (state, action) => {
        state.interactionStatus = StatusTypes.Rejected;
        state.interactionError = action.payload.state?.errorMessage;
      })

      .addCase(guideChatRequest.getWalletConnectedInfo.pending, (state) => {
        state.nextStepStatus = StatusTypes.Loading;
        state.nextStepError = null;
      })
      .addCase(guideChatRequest.getWalletConnectedInfo.fulfilled, (state) => {
        state.nextStepStatus = StatusTypes.Resolved;
      })
      .addCase(guideChatRequest.getWalletConnectedInfo.rejected, (state, action) => {
        state.nextStepStatus = StatusTypes.Rejected;
        state.nextStepError = processNextStepError({ payload: action.payload });
      });
  },
});

export const {
  resetGuideChatState,
  setChoosenGuideInfo,
  addUserMessageByStepId,
  setIsStepConfirmed,
  clearChat,
} = guideChatSlice.actions;

/**
 *
 * @param {*} state
 * @returns {initialState}
 */
export const guideChatStore = (state) => state.guideChat;

export default guideChatSlice.reducer;
